<template>
  <div>
    <el-dialog title="章节"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <div class="cascader_warp">
        <el-row class="row_center">
          <el-cascader placeholder="请选择"
                       style="width:400px"
                       v-if="dialogVisible"
                       ref="cascaderRef"
                       v-model="cascaderList"
                       :show-all-levels="false"
                       :options="sectionList"
                       :props="{ multiple: true,
                       label:'chapter_desc',
                       value:'chapter_id',
                       children:'child' ,
                       checkStrictly :true,
                       emitPath:false}"
                       filterable>
          </el-cascader>
        </el-row>
      </div>
      <div class="line_input_warp">
        <el-row :gutter="10"
                style="margin-right:0"
                v-for="(item,index) in list"
                :key="index"
                :label="item.label">
          <el-col class="left_label"
                  v-if="item.level == 2"
                  :span="12">
            {{item.label}}
          </el-col>
          <el-col style="text-align:right;padding-left:3px"
                  v-if="item.level == 2"
                  :span="12">
            <el-input-number v-model="item.chapter_score"
                             @change="handleChange"
                             :min="0"
                             :max="100"
                             label="描述文字">
            </el-input-number>
          </el-col>
        </el-row>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="handleSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      options: [
        {
          value: 'zhinan',
          label: '指南',
        }
      ],
      cascaderList: [],
      sectionList: [],
      list: [],
      editArr: []
    }
  },
  watch: {
    cascaderList: {
      handler (newVal, oldVal) {
        if (newVal.length < oldVal.length) {
          for (let index = 0; index < oldVal.length; index++) {
            if (newVal.indexOf(oldVal[index]) == -1) {
              this.list.splice(index, 1)
            }
          }
          return
        }
        try {
          const checkedNodes = this.$refs['cascaderRef'].getCheckedNodes()
          checkedNodes.forEach(ele => {
            if (oldVal.indexOf(ele.data.chapter_id) == -1) {
              let obj = {
                chapter_score: 0,
                chapter_id: ele.data.chapter_id,
                label: ele.data.chapter_desc,
                level: ele.level
              }
              this.list.push(obj)
            }
          })
        }
        catch { }
      },
      deep: true
    },
    editArr: {
      handler () {
        this.editArr.forEach(item => {
          this.cascaderList.push(item.chapter_id)
        })
        this.list = JSON.parse(JSON.stringify(this.editArr))
      },
      deep: true
    }
  },
  mounted () {
    // this.getsectionList()
  },
  methods: {
    handleSave () {
      console.log(this.list);
      var ozhangjie = document.getElementById('zhangjie')
      let str = ''
      let allScore = 0
      this.list.forEach(item => {
        if (item.level == 2) {
          str += item.label + ':' + item.chapter_score + ','
          allScore += Number(item.chapter_score)
        } else {
          str += item.label + ','
        }
      })
      if (allScore > this.$parent.form.questionScore) {
        return this.$message.warning('当前章节总分不等于题目分数！')
      }
      this.$parent.form.chapters = JSON.parse(JSON.stringify(this.list))
      ozhangjie.value = str
      if (this.$route.path == '/examinationEntry/examinationEntry/edit') {
        this.$http({
          url: '/api/v1/question/edit_chapter_score',
          method: 'post',
          data: {
            question_code: this.$parent.form.questionCode,
            chapter: this.list
          }
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.dialogVisible = false
        })
      } else {
        this.dialogVisible = false
      }
    },
    handleClose () {
      this.list = []
      this.cascaderList = []
      this.dialogVisible = false
    },
    handleChange (num) {
      // 在这里处理类型不同的情况
    },
    getsectionList () {
      this.$http({
        url: '/api/v1/question/chapter',
        method: 'get',
        params: {
          subject_id: this.$parent.parentForm.subject_id || this.$parent.form.subject_id
        }
      }).then(res => {
        this.sectionList = res.data.list
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.cascader_warp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row_center {
  display: flex;
  justify-content: center;
}
.line_input_warp {
  width: 400px;
  margin: 0 auto;
  .el-row {
    margin: 10px 0;
    line-height: 36px;
  }
}
::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
</style>